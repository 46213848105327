import gsap from "gsap"

(function blur() {
  const blurProperty = gsap.utils.checkPrefix('filter');
  const blurExp = /blur\((.+)?px\)/;
  const getBlurMatch = (target) => (gsap.getProperty(target, blurProperty) || '').match(blurExp) || [];

  gsap.registerPlugin({
    name: 'blur',
    get(target) {
      return +(getBlurMatch(target)[1]) || 0;
    },
    init(target, endValue) {
      let theEndValue = endValue;
      const data = this;
      let filter = gsap.getProperty(target, blurProperty);
      const endBlur = `blur(${theEndValue}px)`;
      const match = getBlurMatch(target)[0];
      let index;
      if (filter === 'none') {
        filter = '';
      }
      if (match) {
        index = filter.indexOf(match);
        theEndValue = filter.substr(0, index) + endBlur + filter.substr(index + match.length);
      } else {
        theEndValue = filter + endBlur;
        filter += filter ? ' blur(0px)' : 'blur(0px)';
      }
      data.target = target;
      data.interp = gsap.utils.interpolate(filter, theEndValue);
    },
    render(progress, data) {
      const theData = data;
      theData.target.style[blurProperty] = theData.interp(progress);
    }
  });
}());
